@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    margin: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    @include remove-highlight();
    @include remove-tap-highlight();

    &.open{
      margin-bottom: $margin-xs;
    }

    > p {
      font-family: "Manrope-SemiBold";
      font-size: $small;
      color: $text-light;
    }
    svg{
      color: $text-light;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      list-style: none;
      white-space: nowrap;
      &:not(:first-child) {
        margin-left: $margin-sm;
      }
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 5px;
        @include status-background();
      }
      p {
        color: $text-light;
        font-size: $small;
      }
    }
  }
}
