
@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label{
    margin-right: 10px;
  }
}

@mixin label {
  font-family: 'Manrope-Bold', sans-serif;
  font-size: $small;
  line-height: 17px;
  color: $text-color;
  white-space: nowrap;
}

@mixin text {
  font-weight: 400;
  font-size: $small;
  line-height: 17px;
  color: $text-color;
}

@mixin input {
    display: block;
    border: none;
    outline: none;
    border-radius: 0px;
    width: 100%;
    // min-width: 0px;
    box-sizing: border-box;
    background-color: $light-grey;
    border-radius: $radius;
    padding: 7px $margin-xs;
    @include text;

    // @media screen and (min-width: $sm-screen) {
    //   min-width: 220px;
    // }

    &::placeholder {
      color : #717A97;
    }
}

@mixin tagform {
  @include tag;
}

@mixin primary {
  .input {
    @include input;
    background-color: $white;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: $radius;
  }
}

@mixin login {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 50px;;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 28px 0px rgb(156 167 177 / 30%);
    color: $text-color;
  }
  label {
    color: $text-color;
  }
};