@import '../../styles/variables';

.reset-password{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
  background-color: $light-grey;

  .col{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:first-child{
      min-height: 100%;
      background-image: url('../../assets/images/auth-image.png');
      background-size: cover;
      width: 0;
      @media screen and (min-width: $sm-screen) {
        width: 30%;
      }
      @media screen and (min-width: $md-screen) {
        width: 50%;
      }
    }
    &:nth-child(2){
      width: 100%;
      min-height: 100%;
      @media screen and (min-width: $sm-screen) {
        width: 70%;
      }
      @media screen and (min-width: $md-screen) {
        width: 50%;
      }
    }
  }

  .container-form{
    position: absolute;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
    padding: $margin-lg;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    h1{
      font-size: $medium;
      margin: 0 0 $margin-md 0;
    }
  }
}
