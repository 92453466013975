@import '../../../styles/_variables.scss';

.containerField{
  .selectIcon{
    font-size: 24px;
    color: $primary-color;
    svg{
      position: relative;
      top: 2px;
    }
  }
  .items{
    margin-top: $margin-xs;
    >*:not(:last-child){
      margin-bottom: 3px;
    }
  }
}