@import "../../styles/_variables.scss";

.planning {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin: 0;
  }

  .row {
    display: flex;
    width: 100%;
    flex-direction: row;

    &.header{
      padding-left: 45px;
    }

    &:nth-child(odd) {
      .legend-line p {
        visibility: hidden; 
      }
    }

    &:nth-child(even) {
      .containerCell .cell {
        border-bottom: solid 1px rgba(224, 224, 224, 0.3);
      }
    }

    .legend-line {
      height: 1px;
      left: 0;
      right: 0;

      p {
        position: relative;
        font-family: 'Manrope-Bold';
        font-size: 10px;
        color: $text-color;
        width: 45px;
        text-transform: uppercase;
        cursor: pointer;

        svg {
          position: absolute;
          top: 3px;
        }

        .today {
          position: relative;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $primary-color;
          margin-right: 5px;
        }
      }
    }

    .containerCell {
      display: flex;
      width: 100%;
      flex-direction: row;

      .cell {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        border-bottom: solid 1px #E0E0E0;

        &.closed {
          background-color: #ECEFF5 !important;
          .droppable {
            background-color: #ECEFF5 !important;
          }
        }

        &.today .droppable,
        &.today.header{
          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 5px;
            height: 100%;
            background-color: rgba(106, 161, 135, 0.15);
          }
        }

        &:not(.header):hover .droppable:not(.dragging) {
          background-color: rgba(106, 161, 135, 0.15);
        }

        .droppable {
          position: relative;
          display: flex;
          align-items: flex-start;
          flex: 1;
          background-color: $white;
          // height: 25px;
          min-height: 25px;
          // max-height: 25px;
          cursor: pointer;
          .placeholderEvent {
            position: absolute;
            pointer-events: none;
            height: 25px;
            &.hover {
              position: absolute;
              z-index: 1000;
              flex: 1;
              max-width: 100%;
              background-color: rgba(106, 161, 135, 0.15);
            }
          }
        }

        &:not(:first-child) {
          border-left: solid 1px #E0E0E0;
          &.today {
            border-left: solid 1px $primary-color;
          }
        }

        &.header {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          height: 60px;
          height: fit-content;
          background-color: $white;
          padding: $margin-xs $margin-sm;

          &.today p{
            color: $primary-color;
          }

          p {
            text-align: center;
            margin: 0;
            text-transform: uppercase;

            .month {
              font-family: 'Manrope-Regular';
              color: $text-light;
              font-size: 10px; 
            }

            &:first-child {
              color: $text-light;
              font-size: 10px; 
            }
            &:last-child {
              font-family: 'Manrope-Bold';
              font-size: $medium; 
            }
          }
        }

      }
    }
  }
}
