@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }
  
  input{
    @include input();
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

  span {
    display: inline-block;
    width: 100%;
    &.highlight {
      border-radius: 5px;
      box-shadow: 0 0 0 1px red;
    }
  }
  
}
