@import "../../styles/_variables.scss";

.planning {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 55px;

  p {
    margin: 0;
  }

  .row {
    display: flex;
    width: 100%;
    flex-direction: row;

    &:last-child .containerCell .cell {
      border-radius: 0 0 5px 5px;
    }

    .legend-line {
      position: absolute;
      border-bottom: dashed 1px #6aa287;
      height: 1px;
      left: 0;
      right: 0;
      p {
        position: relative;
        transform: translate(0, 3px);
        width: 100px;
        text-transform: uppercase;
        line-height: 15px;
        cursor: pointer;

        svg {
          position: absolute;
          top: 1px;
          // right: 0;
        }

        .today {
          position: relative;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $primary-color;
          margin-right: 5px;
        }
      }
    }

    .containerCell {
      display: flex;
      width: 100%;
      flex-direction: row;

      .cell {
        display: flex;
        flex-direction: column;
        flex: 1;

        &:not(.closed) {
          min-height: 60px;
          .droppable {
            padding: $margin-xs;
          }
        }

        &.closed {
          padding: $margin-xs;
          background-color: rgba(106, 161, 135, 0.15);
          p {
            font-family: "Manrope-SemiBold";
            text-align: center;
            color: $text-light;
            opacity: 0.5;
          }
        }

        .droppable {
          position: relative;
          flex: 1;
          margin-bottom: calc(-1 * $margin-xs);
          &:after{
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            bottom: $margin-xs;
            display: block;
            width: 100%;
            transition: background-color 0.4s ease-in-out;
          }
          &.hover:after{
            background-color: rgba(106, 161, 135, 0.35);
          }
          &:not(.hover):after {
            background: rgba(106, 161, 135, 0.15),
          }
        }

        &:not(:first-child) {
          margin-left: $margin-sm;
        }

        &.header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 60px;
          border-radius: 5px 5px 0 0;
          height: fit-content;
          background-color: rgba(106, 161, 135, 0.15);
          p {
            text-align: center;
          }
        }

        .containerTask {
          position: relative;
          z-index: 1;
          height: fit-content;
          margin-bottom: $margin-xs;
        }
      }
    }
  }
}
