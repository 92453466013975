@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.modalSend {
  label {
    display: block;
    font-family: "Manrope-Regular", sans-serif;
    color: $white;
    font-size: $regular;
    margin-bottom: $margin-xs;
    .docName {
      font-family: "Manrope-Bold", sans-serif;
      text-decoration: underline;
    }
  }

  .container-field{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    >:first-child {
      flex: 1;
    }
    button {
      margin-left: $margin-xs;
      height: 31px;
    }

    .email {
      min-width: 230px;
    }

    .close{
      position: absolute;
      bottom: 1px;
      left: -55px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      transform: translate(50%, 0%);
      margin: 0;
      font-size: 28px;
      cursor: pointer;
      svg {
        position: relative;
        top: -6px;
        left: -10px;
        transform: rotate(45deg);
      }
    }
  }

  .error {
    max-width: 310px;
    width: 100%;
    background-color: $warn;
    padding: 5px $margin-xs;
    margin-top: $margin-xs;
    border-radius: 5px;
    p {
      color: $white;
      margin: 0;
      text-align: center;
    }
  }

}