@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";
@import "../../../lib/HooksFormFields/form-mixin";

.containerActions {
  // width: 157px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(113, 122, 151, 0.21);
  padding: $margin-sm 0;
  >div {
    display: flex;
    cursor: pointer;
    padding: 0 $margin-xs;

    svg {
      color: #717A97;
      margin-right: 3px;
    }

    &.moveFolderTo {
      display: block;
      border-bottom: 1px solid rgba(113, 122, 151, 0.2);

      &:not(.opened) {
        padding-bottom: $margin-xs;
      }

      .success-move{
        color: $text-light;
      }

      .titleAction {
        display: flex;
        align-items: center;
        p {
          white-space: nowrap;
        }
        >svg{
          margin-left: $margin-sm;
          color: $text-light;
        }

        .loader {
          position: relative;
          top: 2px;
          width: 20px;
          height: 20px;
          margin-left: $margin-xs;
          > div {
            top: 0;
            left: 0;
            svg {
              color: $text-light;
            }
          }
        }

      }

      .fieldSearch {
        margin: 5px 0;
        padding-right: $margin-sm;
        input {
          display: block;
          border: none;
          outline: none;
          border-radius: 0px;
          width: 100%;
          box-sizing: border-box;
          background-color: $light-grey;
          border-radius: $radius;
          padding: 5px 7px;
          font-weight: 400;
          font-size: $small;
          line-height: 12px;
          color: $text-color;

          &::placeholder {
            color: #717a97;
          }
        }
      }

      ul {
        margin: 0 0 0 5px;
        padding: 0;
        max-height: calc( 20px * 5);
        width: 100%;
        overflow: auto;
        padding-bottom: $margin-xs;
        li {
          display: flex;
          align-items: center;
          list-style: none;
          white-space: nowrap;
          .status {
            width: 10px;
            height: 4px;
            border-radius: 2px;
            margin-right: 5px;
            @include status-background();
          }
          p {
            font-size: $small;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      line-height: 22px;
    }
    img {
      object-fit: contain;
      width: 16px;
      margin-right: 10px;
    }
  }
}

.foldersList {
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      padding: $margin-xs 0 $margin-xs $margin-xs;
      border-radius: 8px;
      box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
      @include remove-tap-highlight;
      &:not(:last-child) {
        margin-bottom: $margin-xs;
      }

      input {
        @include input;
      }

      > div:first-child {
        display: flex;
        align-items: center;

        .loader {
          position: relative;
          width: 20px;
          height: 20px;
          margin-right: $margin-xs;
          > div {
            top: 0;
            left: 0;
          }
        }

        p {
          margin: 0;
          font-family: "Manrope-Bold";
        }
        img {
          margin-right: $margin-xs;
        }
      }

      .toggle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        span {
          padding: 0 $margin-xs;
          opacity: 0.5;
          transition: opacity 0.4s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
