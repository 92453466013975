@import "../../styles/_variables.scss";

.inputFile {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  border: 1px dashed $primary-color;
  background: rgba(106, 162, 135, 0.12);
  height: 78px;
  width: 100%;
  border-radius: 8px;

  input + div:not(.dropZone) {
    background-color: transparent;
    border: none;
    font-family: "Manrope-Bold";
    font-size: $regular;
    color: $primary-color;
    text-align: center;
    opacity: 1;
    pointer-events: none;
  }

  input + div:not(.dropZone) + .dropZone {
    opacity: 0;
  }

  > .dropZone {
    width: 100%;
    pointer-events: none;
  }

  p {
    font-family: "Manrope-Bold";
    font-size: $regular;
    color: $primary-color;
    text-align: center;
  }
}