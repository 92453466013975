@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.modalArticles {
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-md;
  }

}

.parc {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-width: 100%;
  width: 100%;
  height: 100%;

  @media screen and (min-width: $md-screen) {
    min-width: inherit;
  }

  h3 {
    font-family: "Manrope-Bold";
    font-weight: normal;
    font-size: $small;
    letter-spacing: 1px;
    color: $text-color;
    margin: 0;
  }

  .container {
    position: relative;
    @include container();
    max-width: 1200px;
    flex: 1;

    .header {
      position: relative;
      display: flex;
      z-index: 10;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $margin-xs;

      .filters {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          margin-right: $margin-xs;
        }

        p {
          font-family: 'Manrope-Bold';
          margin: 0 $margin-xs;
          text-decoration: underline;
          cursor: pointer;
          &.selected {
            color: $primary-color;
          }
        }

        .filterNotAssigned {
          display: flex;
          >div {
            min-width: 110px
          }
          >div:first-child {
            margin-right: $margin-xs;
          }
        }

      }
  
      .fieldSearch {
        display: flex;
        align-items: center;
        background-color: $white;
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
        border-radius: $radius;
        >div:first-child {
          flex: 1;
          input {
            box-shadow: none;
            min-width: 250px;
          }
        }
    
        .icon{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 26px;
          width: 26px;
          color: $primary-color;
        }
      }

    }

    .notAssignTasks {
      position: relative;
      z-index: 3;

      .containerBtn {
        position: absolute;
        z-index: 2;
        right: $margin-sm;
        transform: translateY(-50%);
        button {
          height: 28px;
          max-height: 28px;
          &:last-child {
            margin-left: $margin-xs;
            font-size:16px;
            vertical-align: bottom;
          }

          svg.loading {
            animation: rotation 0.3s linear infinite;
            @keyframes rotation {
              from {
                 transform: rotate(0deg);
              }
              to {
                 transform: rotate(-180deg);
              }
           }
          }

        }
      }


      .containerTasks {
        position: relative;
        z-index: 1;
        padding: $margin-xs;
        background-color: rgba(113, 122, 151, 0.1);
        margin: 0 0 $margin-md 0;
        border-radius: 5px;

        .toggle {
          position: absolute;
          background: $white;
          border-radius: 5px 0 5px 0;
          border: solid 1px rgba(113, 122, 151, 0.1);
          cursor: pointer;

          &.top {
            top: 0;
            left: 0;
          }

          &:not(.top) {
            bottom: 0;
            right: 0;
          }

          svg {
            position: relative;
            top: 2px;
            color: $text-light;
          }
        }

        .droppable {
          padding-top: 25px;
        }

        > div:last-child:not(.containerTasksClosed) {
          display: grid;
          grid-gap: $margin-xs;
          grid-template-columns: repeat(auto-fit, calc((100% - 160px) / 3));
          min-height: 40px;
        }
      }

      .containerTasksClosed {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        width: 100%;
        p {
          font-family: 'Manrope-Bold';
          margin: 0;
          text-align: center;
          >span{
            display: inline-block;
            margin-right: 5px;
            background-color: $warn;
            color: $white;
            min-width: 18px;
            padding: 0 3px;
            height: 18px;
            border-radius: 9px;
          }
        }
      }
    }

    .planningActions {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      margin-bottom: $margin-sm;
      .weekNav {
        display: flex;
        align-items: center;
        margin-right: $margin-sm;
        h3 {
          margin-bottom: 0;
          margin-right: $margin-xs;
        }
        button {
          font-size: 22px;
          color: $text-color;
          background-color: rgba(218, 225, 245, 1);
          border-color: rgba(218, 225, 245, 1);
          padding: 0px 2px;
          &:nth-child(2) {
            border-radius: 5px 0 0 5px;
            margin-right: 1px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
          > svg {
            position: relative;
            top: 2px;
          }
        }
      }

      .containerSelect {
        max-width: 200px;
        &:not(:last-child) {
          margin-right: $margin-sm;
        }
      }

      .filters{
        display: flex;
        align-items: center;
        margin-left: auto;
      }

    }
  }
}
