@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.containerActions {
  min-width: 157px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(113, 122, 151, 0.21);
  padding: $margin-sm 0;

  > div {
    display: flex;
    cursor: pointer;
    padding: 0 $margin-xs;

    &.moveFolderTo {
      display: block;
      border-bottom: 1px solid rgba(113, 122, 151, 0.2);

      &:not(.opened) {
        padding-bottom: $margin-xs;
      }

      .success-move{
        color: $text-light;
      }

      .titleAction {
        display: flex;
        align-items: center;
        p {
          white-space: nowrap;
        }
        > svg {
          margin-left: $margin-sm;
          color: $text-light;
        }

        .loader {
          position: relative;
          top: 2px;
          width: 20px;
          height: 20px;
          margin-left: $margin-xs;
          > div {
            top: 0;
            left: 0;
            svg {
              color: $text-light;
            }
          }
        }
      }

      .fieldSearch {
        margin: 5px 0;
        padding-right: $margin-sm;
        input {
          display: block;
          border: none;
          outline: none;
          border-radius: 0px;
          width: 100%;
          box-sizing: border-box;
          background-color: $light-grey;
          border-radius: $radius;
          padding: 5px 7px;
          font-weight: 400;
          font-size: $small;
          line-height: 12px;
          color: $text-color;

          &::placeholder {
            color: #717a97;
          }
        }
      }

      ul {
        margin: 0 0 0 5px;
        padding: 0;
        max-height: calc(20px * 5);
        width: 100%;
        overflow: auto;
        padding-bottom: $margin-xs;
        li {
          display: flex;
          align-items: center;
          list-style: none;
          white-space: nowrap;
          .status {
            width: 10px;
            height: 4px;
            border-radius: 2px;
            margin-right: 5px;
            @include status-background();
          }
          p {
            font-size: $small;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      line-height: 22px;
    }
    img {
      object-fit: contain;
      width: 16px;
      margin-right: 10px;
    }
  }
}

.wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 170px;
  height: 137px;

  svg {
    position: absolute;
    top: 0;
    pointer-events: none;
    max-width: 100%;
    filter: drop-shadow(0px 18px 32px rgba(208, 210, 218, 0.15));
  }
}

.folder {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  z-index: 1;
  > .container-status {
    position: relative;
    top: 1px;
    padding: 10px 10px 0px 10px;
    // border-radius: 5px 0 0 0;
    .status {
      width: 34px;
      height: 8px;
      border-radius: 4px;
      @include status-background();
    }
  }

  .container-actions {
    position: absolute;
    right: 0;
    padding: 10px 10px 0px 10px;
    margin: 22px 0 0 auto;
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    .loader {
      position: relative;
      width: 20px;
      height: 20px;
      > div {
        top: 0;
        left: 0;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $margin-sm;
    border-radius: 0 5px 5px 5px;
    // clip-path: polygon(0% 0%, 20% 0%, 28% 15%, 100% 15%, 100% 100%, 0% 100%);
    p {
      margin: 0;
    }

    .initials {
      position: absolute;
      right: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $text-color;
      > span {
        position: relative;
        top: -1px;
        font-family: "Manrope-Bold";
        font-size: $small;
        color: $white;
      }
    }

    .info {
      width: 100%;
      margin-top: auto;
      .caseRef,
      .name {
        font-family: "Manrope-Bold";
      }
      .name {
        font-size: $medium;
        &[contenteditable="true"] {
          display: inline-block;
          border: none;
          outline: none;
          background-color: $light-grey;
          border-radius: $radius;
          padding: 3px 5px 3px 3px;
          margin: -3px;
        }
      }

      .lastUpdate {
        margin-top: 5px;
        color: $text-light;
      }
    }
  }
}
