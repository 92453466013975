@import "../../../../styles/_variables.scss";

.modalCases {
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;

  .addCases {
    margin: 0 0 $margin-sm 0;
    label {
      display: block;
      font-family: 'Manrope-Bold', sans-serif;
      font-size: $small;
      line-height: 17px;
      color: $text-color;
      white-space: nowrap;
      margin-bottom: $margin-xs;
    }
  }

  .containerButtons {
    text-align: center;
  }

}

.form {
  background-color: $white;
  padding: $margin-md $margin-md 50px $margin-md;
  border-radius: $radius;
  max-height: 100%;
  overflow-y: auto;

  h1 {
    text-align: center;
    margin: 0 0 $margin-sm 0;
  }

  .back,
  .containerField {
    margin-bottom: $margin-sm;
  }

  .clients {
    display: flex;
    align-items: flex-start;
    max-width: 850px;
    width: 100%;

    button {
      margin-top: $margin-sm;
    }

    .list {
      flex: 1;
    }

    .list .client {
      border-bottom: 1px solid rgba(113, 122, 151, 0.11);
      margin: $margin-sm 0;
      &:last-child {
        margin-bottom: 0;
      }
      h4 {
        margin: 0 0 $margin-sm 0;
        >span {
          font-family: 'Manrope-Regular';
        }
      }

      .linkedTaskNumber {
        font-family: 'Manrope-SemiBold';
        color: $text-light;
        img {
          position: relative;
          top: -3px;
          vertical-align: bottom;
          height: 15px;
          margin-right: 2px;
        }
      }

      .contact {
        display: grid;
        grid-template-columns:  160px 1fr;
        p:nth-child(odd) {
          font-family: 'Manrope-Bold', sans-serif;
          font-size: $small;
          line-height: 17px;
          color: $text-color;
          white-space: nowrap;
        }

        p {
          margin: 0 0 $margin-xs 0;
        }

      }

    }

    .list {
      .newCases{
        margin: $margin-xs 0 $margin-md 0;
        padding-bottom: $margin-sm;
        border-bottom: 1px solid rgba(113, 122, 151, 0.11);

        >label {
          display: inline-block;
          font-family: 'Manrope-Bold', sans-serif;
          font-size: $small;
          line-height: 17px;
          color: $text-color;
          margin-bottom: $margin-xs;
        }

        .newCase{
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 31px;
          padding: 0 $margin-xs 0 5px;
          border-radius: $radius; 
          background-color: $light-grey;
    
          &:not(:last-child) {
            margin: 0 0 5px 0;
          }
          svg{
            position: relative;
            top: 1px;
            color: $warn;
            font-size: $medium;
            cursor: pointer;
          }
        }
      }
    }

  }

  .informations {
    display: grid;
    grid-template-columns:  160px 1fr;
    border-bottom: 1px solid rgba(113, 122, 151, 0.11);
    padding-bottom: 3px;
    p:nth-child(odd) {
      font-family: 'Manrope-Bold', sans-serif;
      font-size: $small;
      line-height: 17px;
      color: $text-color;
      white-space: nowrap;
    }

    p {
      margin: 0 0 $margin-xs 0;
    }
  }

  .firstRow,
  .addClients,
  .transportDetail {
    max-width: 850px;
    width: 100%;
  }

  .firstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .containerField .dateFields {
      display: flex;
      align-items: center;
  
      .direction {
        padding: 7px 10px;
        border-radius: $radius;
        text-align: center;
        margin-right: $margin-xs;
  
        &.checkIn {
          background-color: $checkIn-color;
        }
        &.checkOut {
          background-color: $checkOut-color;
        }
  
        p {
          font-family: "Manrope-Bold";
          margin: 0;
          color: $white;
        }
      }
  
      .date {
        margin-right: $margin-xs;
      }
  
      .time {
        display: inline-block;
        &:nth-child(2) {
          margin-left: $margin-xs;
        }
      }
    }

    .containerField.type {
      div[class$="-control"] {
        position: relative;
        // box-shadow: 0 0 0 1px red;
        &:after {
          content: '';
          position: absolute;
          z-index: 0;
          display: block;
          background-color: currentcolor;
          opacity: 0.2;
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }
        >div {
          position: relative;
          z-index: 1;
        }
      }
      div[class$="-indicatorContainer"] {
        color: $text-color;
      }
    }
    
    >div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        position: relative;
        top: 3px;
        margin: 0 0 $margin-md $margin-xs;
        height: 31px;
      }

    }

  }

  .transportDetail h3,
  .load h3,
  .delivery h3 {
    font-family: "Manrope-Bold";
    margin-top: 0;
  } 

  .transportDetail {
    border-bottom: 1px solid rgba(113, 122, 151, 0.11);
    margin: $margin-sm 0;

    .sellPrice {
      display: flex;
      grid-template-columns:  160px 1fr;
      p:nth-child(odd) {
        font-family: 'Manrope-Bold', sans-serif;
        font-size: $small;
        line-height: 17px;
        color: $text-color;
        white-space: nowrap;
      }

      p:nth-child(even) {
        margin-left: $margin-xs;
      }

      p {
        margin: 0 0 $margin-xs 0;
      }

    }
    .row {
      display: flex;

      &:first-child,
      &:nth-child(2) {
        .containerField {
          width: 50%;
        }
      }

      &:nth-last-child(-n + 2) {
        .containerField {
          width: 40%;
          &:last-child {
            width: 20%;
          }
        }
      }

      .containerField:not(:last-child) {
        margin-right: $margin-xs;
      }
    }

    .row.comment {
      margin-bottom: $margin-sm;

      .containerField {
        width: 100% !important;
      }
    }


  }

  .load {
    border-bottom: 1px solid rgba(113, 122, 151, 0.11);
    margin: $margin-sm 0;
  }
  
  .load,
  .delivery {
    max-width: 550px;
    width: 100%;

    .loadDate,
    .deliveryDate {
      display: flex;
      width: 100%;
      >div:first-child {
        width: 100%;
      }
      .time {
        margin-left: $margin-sm;
      }
    }


    .location {
      display: flex;
      flex-wrap: wrap;
      > p {
        font-family: "Manrope-Bold";
        max-width: 135px;
        flex: 1;
        margin-right: $margin-xs;
      }

      .locationFields {
        flex: 1;
        .row {
          display: flex;
          flex: 1;
          width: 100%;
          .containerField {
            width: 50%;
            &:not(:last-child) {
              margin-right: $margin-xs;
            }
          }
        }
      }


      >.containerField {
        flex: 1;
        width: 100%;
        min-width: 100%;
      }
    }
  }

  .errorMessage {
    margin-bottom: $margin-sm;
    background-color: rgba(245, 91, 93, 0.1);
    padding: 5px $margin-xs;
    border-radius: $radius;
    p {
      font-family: "Manrope-Bold";
    }
  }

  .transportActions {
    position: absolute;
    bottom: $margin-sm;
    left: $margin-sm;
    .btnDelete {
      background-color: $white;
      border-color: $warn;
      color: $text-color;
    }
    button:not(:last-child) {
      margin-right: $margin-xs;
    }
  }

}


