@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';


.modalSend {
  label {
    display: block;
    font-family: "Manrope-Regular", sans-serif;
    color: $white;
    font-size: $regular;
    margin-bottom: $margin-xs;
    .docName {
      font-family: "Manrope-Bold", sans-serif;
      text-decoration: underline;
    }
  }

  .container-field{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    >:first-child {
      flex: 1;
    }
    button {
      margin-left: $margin-xs;
      height: 31px;
    }

    .email {
      min-width: 230px;
    }

    .close{
      position: absolute;
      bottom: 1px;
      left: -55px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      transform: translate(50%, 0%);
      margin: 0;
      font-size: 28px;
      cursor: pointer;
      svg {
        position: relative;
        top: -6px;
        left: -10px;
        transform: rotate(45deg);
      }
    }
  }

  .error {
    max-width: 310px;
    width: 100%;
    background-color: $warn;
    padding: 5px $margin-xs;
    margin-top: $margin-xs;
    border-radius: 5px;
    p {
      color: $white;
      margin: 0;
      text-align: center;
    }
  }

}

.cases{
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  height: 100%;
  
  .search{
    @include container();
    // padding-top: 0;
    padding-bottom: 0;
  }

  .container{
    position: relative;
    @include container();
    flex: 1;
    overflow: auto;
    padding-top: 0;

    >div {
      left: $margin-md;
      right: $margin-md;
      min-height: 100%;
      height: 100%;
    }

    .noCase{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      p{
        text-align: center;
      }
      img{
        display: block;
      }
    }

    .header{
      display: flex;
      justify-content: space-between;
      margin-bottom: $margin-xs;
      h3{
        font-family: 'Manrope-Regular';
        font-weight: normal;
        font-size: $small;
        letter-spacing: 1px;
        color: $text-light;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .containerCases,
    .containerFolders{
      >div:last-child {
        margin-bottom: $margin-md;
      }
    }

  }
}