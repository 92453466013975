@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.modalImage {
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;
  img {
    width: 100%;
  }
}

.tasks .clientName,
.modalArticles .clientName {
  font-size: $regular;
  font-family: "Manrope-Bold";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalArticles {
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;
}

.task {
  position: relative;
  z-index: 1;

  @include remove-highlight();
  @include remove-tap-highlight();

  &.disabled {
    opacity: 0.30;
  }

  &.opened {
    .container {
      position: absolute;
      align-items: flex-start;
      padding-top: 15px;
      width: 140%;
      box-shadow: 0px 18px 29px rgba(182, 183, 189, 0.53);
      &.right {
        right: 0;
      }
      .containerAssignments {
        width: 100%;
        margin: $margin-xs 0 0 0;
        padding-top: 5px;
        border-top: 1px solid rgba(113, 122, 151, 0.11);
      }
    }
  }

  &:not(.opened) .container {
    position: relative;
    align-items: center;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  }
  
  .dot {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $warn;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $margin-xs $margin-sm $margin-xs $margin-xs;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid rgba(113, 122, 151, 0.21);
    overflow: hidden;
    min-height: 100%;

    .actions {
      position: absolute;
      top: 0;
      right: 5px;
      display: flex;
      justify-content: flex-end;
      > div {
        cursor: pointer;
        color: $text-light;
      }
      .delete {
        margin-right: 3px;
        cursor: pointer;
        img {
          width: 14px;
        }
      }
      .edit {
        position: relative;
        top: 4px;
      }
      .close {
        svg {
          transform: rotate(45deg);
        }
      }
    }

    .fileImg {
      width: 14px;
      margin-right: 6px;
    }
  }

  p {
    font-size: $small;
    margin: 0;
  }

  img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }

  .info {
    flex: 1;
    width: 0px;
    padding-right: 2px;

    .clientName {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      > span {
        font-family: 'Manrope-Bold';
      }
    }

    .details {
      margin-top: $margin-xs;

      .detailsArticles {
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        line-height: 20px;
        &:hover p {
          text-decoration: underline;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .location {
        display: flex;
        width: 100%;
        margin-bottom: $margin-xs;
        p:not(:last-child) {
          margin-right: $margin-xs;
        }
        p {
          color: $text-light;
          span {
            margin-left: 3px;
            color: $text-color;
          }
        }
      }
      .files {
        padding: 0;
        margin: 0;
        width: 100%;
        li {
          list-style: none;
          a {
            display: flex;
            align-items: center;
            line-height: 20px;
            &:hover p {
              text-decoration: underline;
            }
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .assignments {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .buttonAssignment {
      position: absolute;
      top: 0;
      right: 0;
      background: #ffffff;
      border: 1px solid #d6dae5;
      border-radius: 8px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(40%, -50%);
    }

    ul {
      padding: 0;
      margin: 0 0 0 28px;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        &:not(:first-child) {
          margin-top: 5px;
        }
        .initials + p {
          margin-left: 5px;
        }
      }
    }

    .notAssignment {
      text-align: center;
      width: 100%;
      p {
        color: $text-light;
      }
    }

    .buttonNotAssignment,
    .initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      min-width: 27px;
      height: 27px;
      border-radius: 15px;
      background-color: $text-color;

      p {
        position: relative;
        top: -1px;
        color: $white;
      }
    }

    .buttonNotAssignment {
      cursor: pointer;
      background-color: $medium-grey;
      p {
        color: $text-color;
        font-size: 20px;
      }
    }
  }

  .priority {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    border-radius: 0 8px 8px 0;
    &.basse {
      background: #87cca5;
    }
    &.moyenne {
      background: #fcb36f;
    }
    &.haute {
      background: #f55c5e;
    }
  }

  .warning {
    position: absolute;
    right: 8px;
    bottom: -2px;
    color: $warn;
    font-size: 15px;
  }

}
