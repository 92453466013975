@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.containerActions {
  width: 157px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(113, 122, 151, 0.21);
  padding: $margin-sm;
  div {
    display: flex;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      margin: 0;
    }

    svg {
      color: #717A97;
      margin-right: 3px;
    }

    img {
      object-fit: contain;
      width: 20px;
      margin-right: 5px;
    }
  }
}

.casesList {
  .row {
    cursor: pointer;
    padding: 5px 0;
    @include remove-tap-highlight();

    p {
      margin: 0;
    }

    > div {
      &:last-child {
        flex: 0;
        overflow: hidden;
      }

      @media screen and (min-width: $sm-screen) {
        flex: 0.45;

        &:nth-last-child(2) {
          flex: 0.20;
        }

        &:not(:last-child) {
          padding: 0 $margin-xs;
        }

        &:last-child {
          display: block;
          flex: 0.25;
          overflow: visible;
        }
      }
    }

    &.rowHeader {
      > div p {
        font-size: $small;
      }

      // CELL HEADER ACTION AND CONTROL
      > div:last-child {
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        margin-left: -40px;
      }
    }

    &.rowItem {
      background-color: $white;
      padding: $margin-sm;
      box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
      border-radius: 8px;

      &:not(:last-child) {
        margin-bottom: $margin-sm;
      }

      @media screen and (min-width: $sm-screen) {
        border-bottom: solid 1px rgba(113, 122, 151, 0.2);
        background-color: transparent;
        padding: 5px 0;
        box-shadow: none;
        border-radius: 0px;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      &:hover {
        background-color: $white;
        border-color: transparent;
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
        border-radius: $radius;
      }

      > div {
        @media screen and (max-width: $sm-screen) {
          &:nth-child(even) {
            width: 40%;
          }
          &:nth-child(odd) {
            width: 60%;
          }
        }
        @media screen and (min-width: $sm-screen) {
          // trick to enabled text ellipsis
          // width is ignore by browser in favor of flex
          width: 0px;
        }
        &:nth-child(1) p,
        &:nth-child(2) p {
          font-size: $regular;
          font-family: "Manrope-Bold";
        }
      }

      .cellRef {
        display: flex;
        align-items: center;
        .status {
          width: 18px;
          height: 8px;
          border-radius: 4px;
          margin-right: 5px;
          @include status-background();
        }
      }

      .cellAction {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
          margin-right: $margin-sm;
          color: $text-light;
        }

        .toggle {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          span {
            padding: 0 $margin-xs;
            opacity: 0.5;
            transition: opacity 0.4s ease-in-out;
            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .cellCommercial {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        > p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .initials {
          position: relative;
          top: 1px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          min-width: 24px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: $text-color;
          > span {
            position: relative;
            top: -1px;
            font-family: "Manrope-Bold";
            font-size: 10px;
            color: $white;
          }
        }
      }

      .cellClient,
      .cellDate{
        p{
          text-align: right;
        }
        @media screen and (min-width: $sm-screen) {
          p{
            text-align: left;
          }
        }
      }


    }
  }
}
