@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-select{

  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.select{
      margin-top: 10px;
    }
  }

  .select{
    width: 100%;
    min-width: 180px;
  }


  span {
    display: inline-block;
    width: 100%;
    &.highlight {
      border-radius: 5px;
      box-shadow: 0 0 0 1px red;
    }
  }

}
