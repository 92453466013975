@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.caseFilters {
  margin-bottom: $margin-md;
  .fieldSearch {
    display: flex;
    align-items: center;
    background-color: $white;
    margin-bottom: $margin-xs;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: $radius;
    >div:first-child {
      flex: 1;
      input {
        box-shadow: none;
      }
    }

    .toggle{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 26px;
      background-color: $white;
      border-radius: 50%;
      margin-right: $margin-xs;
      transition: all .2s ease-in-out;
      cursor: pointer;
      @include remove-tap-highlight();
      &.actived{
        background-color: $primary-color;
      }
    }

  }
  .selectIcon{
    font-size: 24px;
    color: $primary-color;
    svg{
      position: relative;
      top: 2px;
    }
  }
  .advancedFilters{
    display: none;
    &.opened {
      display: flex;
      flex-wrap: wrap;
    }
    .containerField{
      min-width: 25%;
      margin-right: $margin-xs;
      margin-bottom: $margin-xs;
      @media screen and (min-width: $sm-screen) {
        width: 20%;
        min-width: inherit;
        margin-bottom: 0;
      }
    }

    .reset{
      display: flex;
      align-items: center;
      height: 31px;
      p {
        font-family: 'Manrope-SemiBold';
        margin: 0;
        color: $text-light;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .item-client,
    .item-commercial,
    .item-status {
      height: 24px;
      max-width: 100%;
      .delete {
        cursor: pointer;
        position: relative;
        top: 2px;
        transform: rotate(45deg);
        font-size: 24px;
        color: $text-color;
      }
    }

    .item-commercial{
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p{
        margin: 0;
      }

      .profile{
        display: flex;
        align-items: center;
        overflow: hidden;
        >p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .initials{
        position: relative;
        top: 1px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: $text-color;
        p{
          position: relative;
          font-family: 'Manrope-Bold';
          font-size: 10px;
          color: $white;
        }
      }
    }

    .item-status{
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p{
        margin: 0;
      }
      .status{
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 5px;
        @include status-background();
      }
    }

    .item-client{
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p{
        margin: 0;
      }
    }

  }
}