@import "../../styles/_variables.scss";

.loader {
  position: absolute;
  z-index: 1000;
  top: calc(50% - 20px);
  left: calc(50% - 20px);

  .primary,
  .secondary,
  .third {
    top: 0;
  }

  .primary {
    color: $primary-color;
  }

  .secondary {
    color: $white;
  }

  .third {
    color: $text-color;
  }

  .critical {
    color: $warn;
  }
}
