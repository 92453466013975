@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $margin-sm;

  p {
    margin: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    @include remove-highlight();
    @include remove-tap-highlight();

    &.open{
      margin-bottom: $margin-xs;
    }

    > p {
      font-family: "Manrope-SemiBold";
      font-size: $small;
      color: $text-light;
    }
    svg{
      color: $text-light;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      list-style: none;
      white-space: nowrap;
      &:not(:first-child) {
        margin-left: $margin-sm;
      }
      .icon {
        margin-right: 5px;
        svg {
          font-size: 20px;
          fill: $warn;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
      p {
        position: relative;
        top: -2px;
        color: $text-light;
        font-size: $small;
      }
    }
  }
}
