.overlay-modal{
  position: fixed;
  z-index: 1003;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal {
  position: fixed;
  z-index: 1004;
  width: 100%;
  max-height: calc(100% - 60px);
  max-width: 400px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

  display: flex;
  flex-direction: column;
  
  .container {
    overflow-y: auto;
  }

  .close{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    margin: 0;
    font-size: 28px;
    cursor: pointer;
    svg {
      position: relative;
      top: -6px;
      left: -10px;
      transform: rotate(45deg);
    }
  }
}
