@import "./_variables.scss";
@import "./_mixins.scss";

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  background-color: $bg-color;
}

body {
  margin: 0;
  font-family: "Manrope-Regular", sans-serif;
  font-size: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  @media screen and (max-width: $md-screen) {
    flex-direction: column;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2 {
  font-family: "Manrope-Bold", sans-serif;
  font-weight: normal;
  color: $text-color;
}

h3,
h4 {
  font-family: "Manrope-SemiBold", sans-serif;
  font-weight: normal;
  color: $text-color;
}

h1 {
  font-size: $h1;
}

h2 {
  font-size: $medium;
}

h3 {
  font-size: $medium;
}

h4 {
  font-size: $medium;
}

h5 {
  font-family: "Manrope-Bold", sans-serif;
  font-weight: normal;
  font-size: $regular;
  margin-bottom: $margin-sm;
  color: $text-color;
}

p,
a,
li,
input,
textarea {
  font-family: "Manrope-Regular", sans-serif;
  color: $text-color;
  font-size: $small;
  &.bold {
    font-family: "Manrope-SemiBold", sans-serif;
  }
  &.small {
    font-size: $small;
  }
}

a {
  text-underline-offset: 2px;
  @include remove-tap-highlight();
}

button {
  align-items: center;
  justify-content: center;
  font-family: "Manrope-Bold", sans-serif;
  font-size: $small;
  background-color: $secondary-color;
  padding: 4px 10px;
  color: white;
  border: solid 1px $secondary-color;
  border-radius: $radius;
  text-align: center;
  // height: 33px;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;

    &.loader > div {
      position: initial;
    }

    svg {
      position: relative;
      top: -50%;
      vertical-align: middle;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -3px;
  }

  span:first-child:not(.icon),
  .icon + span {
    position: relative;
    top: -1px;
  }

  span + .icon,
  .icon + span {
    margin-left: 3px;
  }

  &.secondary {
    background-color: rgba(106, 161, 135, 0.2);
    border: solid 1px rgba(5, 7, 6, 0.2);
    color: $text-color;
    svg {
      color: $primary-color;
    }
  }

  &.light {
    background-color: rgba(113, 122, 151, 0.04);
    border: solid 1px rgba(113, 122, 151, 0.04);
    color: $text-color;
  }

  &.third {
    background-color: $white;
    border: solid 1px $text-color;
    color: $text-color;
  }

  &.disabled {
    opacity: 0.7;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-on-mobile {
  display: none;
  @media screen and (min-width: $sm-screen) {
    display: block;
  }
}

.only-on-mobile {
  display: block;
  @media screen and (min-width: $sm-screen) {
    display: none;
  }
}
