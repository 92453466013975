@import "../../../../styles/_variables.scss";

.fieldCases {
  .add-cases{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;

    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 23px;
      color: $primary-color;
      font-size: 16px;
    }

    button {
      width: auto;
      margin-left: $margin-sm;
    }
    div:first-child{
      width: 100%;
    }
  }

  .cases{
    margin: $margin-xs 0 $margin-md 0;
    .case{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 31px;
      padding: 0 $margin-xs 0 5px;
      border-radius: $radius; 
      background-color: $light-grey;

      &:not(:last-child) {
        margin: 0 0 5px 0;
      }
      svg{
        position: relative;
        top: 1px;
        color: $warn;
        font-size: $medium;
        cursor: pointer;
      }
    }
  }
}