@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.case {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;

  .container {
    @include container();
    padding-bottom: $margin-xl;
    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: $margin-sm;
      h1 {
        margin: 0;
        a {
          font-family: "Manrope-Bold", sans-serif;
          font-weight: normal;
          font-size: $h1;
          text-transform: uppercase;
          margin: 0 0 $margin-xs 0;
          width: 100%;
          text-decoration: none;
          @media screen and (min-width: $sm-screen) {
            margin: 0;
            width: auto;
          }
        }
        span {
          position: relative;
          top: 3px;
          color: $primary-color;
          margin-left: $margin-xs;
          font-size: $h1;
          cursor: pointer;
          float: right;
          @media screen and (min-width: 450px) {
            float: inherit;
          }
        }
      }

      .fields {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media screen and (min-width: $sm-screen) {
          width: auto;
        }
        .checkboxField {
          display: flex;
          align-items: center;
          margin-right: $margin-sm;
          cursor: pointer;
          @include remove-tap-highlight;
          > svg {
            margin-right: 3px;
            transform: scale(-1, 1);
          }
          p {
            font-family: "Manrope-Bold";
            margin-right: $margin-xs;
          }
          .checkbox {
            width: 18px;
            height: 18px;
            border-radius: 3px;
            background-color: $medium-grey;
            svg {
              font-size: 18px;
            }
          }
        }

        .containerSelect {
          display: inline-block;
          vertical-align: top;
          min-width: 130px;
          margin-right: $margin-xs;
        }

        button.export {
          height: 31px;
          font-size: 18px;
          svg {
            position: relative;
            left: 3px;
          }
        }
      }

      h3 {
        width: 100%;
        margin: 0;
        color: $text-light;
      }

    }

    .pictures {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: $margin-sm;
      padding-bottom: $margin-sm;
      border-bottom: 1px solid rgba(113, 122, 151, 0.2);

      .tile {
        display: flex;
        align-items: center;
        width: 100%;
        padding: $margin-sm;
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: $margin-xs;
        }

        @media screen and (min-width: $sm-screen) {
          width: calc(33% - 5px);
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          width: 38px;
          border-radius: 50%;
          background-color: $light-grey;
          margin-right: $margin-xs;
        }
        .count {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          p {
            margin: 0;
            font-size: $medium;
            &:first-child {
              font-family: "Manrope-Bold";
              font-size: $medium;
              text-transform: uppercase;
            }
            &:last-child {
              color: $text-light;
            }
          }
        }
      }
    }

    .breadcrumbTrail {
      @include breadcrumb();
    }

    .containerInputField {
      margin-bottom: $margin-sm;

      &.disabled {
        pointer-events: none;
      }
    }

    .files,
    .folders {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-sm;
        h3 {
          font-family: "Manrope-Regular";
          font-weight: normal;
          font-size: $small;
          letter-spacing: 1px;
          color: $text-light;
          text-transform: uppercase;
          margin: 0;
        }

        .containerButtons {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          .btnSend {
            margin-right: $margin-xs;
          }
        }

      }
    }

    .files {
      .titleListFiles {
        font-family: "Manrope-Bold";
        color: $text-light;
        margin: 0 0 $margin-xs 0;
      }
      .listFiles {
        margin-bottom: $margin-sm;
      }
      .errorMessage {
        flex: 1;
        min-width: 100%;
        background-color: rgba(245, 91, 93, 0.1);
        border-radius: $radius;
        margin-bottom: $margin-sm;
        padding: 3px $margin-sm;
        p {
          text-align: left;
        }
      }
    }
  }
}
