@import '../../../../styles/_variables.scss';

.form{
  background-color: $white;
  padding-top: $margin-md;
  border-radius: $radius;

  h1 {
    text-align: center;
    margin: 0 0 $margin-sm 0;
  }

  .containerField{
    margin-bottom: $margin-sm;
    padding: 0 $margin-md;

    .dateFields {
      display: flex;
      align-items: center;
      .time {
        display: inline-block;
        &:nth-child(2) {
          margin-left:$margin-xs;
        }
      }
    }

    .directions{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $margin-sm;
  
      button:not(.selected) {
        background-color: $light-grey;
        color: $text-light;
      }
  
      button:first-child.selected {
        background-color: $checkOut-color;
      }

      button:last-child.selected {
        background-color: $checkIn-color;
      }
  
      button {
        border: none;
      }
    }

  }

  .errorMessage{
    margin: 0 $margin-md $margin-sm $margin-md;
    background-color: rgba(245, 91, 93, .1);
    padding: 5px $margin-xs;
    border-radius: $radius;
    p{
      font-family: 'Manrope-Bold';
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: $margin-xs 0;
    }
  }
}