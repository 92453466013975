@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container {
  background-color: #fafcff;
  padding: $margin-md;
  border-radius: $radius;
  max-height: 90%;

  h1 {
    text-align: center;
  }

  .toggle{
    font-family: 'Manrope-SemiBold';
    color: $primary-color;
    cursor: pointer;
    @include remove-highlight();
    @include remove-tap-highlight();
  }

  > ul {
    background-color: $white;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: 8px;
    padding: $margin-xs $margin-sm !important;
    max-height: 450px;
    overflow-y: auto;
  }

  ul {
    padding: 0 $margin-md 0 $margin-md;
    margin: 0;

    li {
      position: relative;
      display: block;
      overflow: hidden;
      cursor: pointer;
      list-style-type: none;
      @include remove-tap-highlight();

      .empty {
        font-family: "Manrope-Regular";
        color: $text-light;
        margin: 3px 0;
      }

      .folder .name{
        .icon {
          position: relative;
          width: 15px;
          height: 13px;
          margin-right: 5px;
          >div {
            top: 0; left: 0;
          }
        }
        p:hover {
          text-decoration: underline;
        }
      }

      .file,
      .folder {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .name{
          width: calc(100% - 19px);
          flex: 1;
          p {
            flex: 1;
            width: 100%;
            font-family: "Manrope-SemiBold";
            margin: 0;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        > span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $margin-sm;
  }
}
