@import "../../../../styles/_variables.scss";

.form {
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;

  h1 {
    text-align: center;
    margin: 0 0 $margin-sm 0;
  }

  .containerField {
    margin-bottom: $margin-sm;
  }

  .optionalFields {
    .title {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0 0 $margin-sm 0;
      overflow: hidden;

      h4 {
        position: relative;
        display: inline-block;
        z-index: 0;
        font-family: "Manrope-Bold";
        font-size: $regular;
        text-align: center;
        color: $text-light;
        background-color: $white;
        padding: 0 $margin-xs;
        margin: 0;
        &:before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          display: block;
          background-color: $text-light;
          left: 0;
          width: 200px;
          height: 1px;
          transform: translate(-100%, 0);
        }
        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 50%;
          display: block;
          background-color: $text-light;
          right: 0;
          width: 200px;
          height: 1px;
          transform: translate(100%, 0);
        }
      }
    }
  }

  .errorMessage {
    margin-bottom: $margin-sm;
    background-color: rgba(245, 91, 93, 0.1);
    padding: 5px $margin-xs;
    border-radius: $radius;
    p {
      font-family: "Manrope-Bold";
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
