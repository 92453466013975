@import '../../../styles/_variables.scss';

.modalImage {
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;
  img {
    width: 100%;
  }
}

.containerActions{
  width: 157px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(113, 122, 151, 0.21);
  padding: $margin-sm;
  div{
    display: flex;
    cursor: pointer;
    &:not(:last-child){
      margin-bottom: 10px;
    }

    svg {
      color: #717A97;
      margin-right: 3px;
    }

    p{
      margin: 0;
    }
    img{
      object-fit: contain;
      width: 20px;
      margin-right: 5px;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px $medium-grey;
  font-size: 45px;
  cursor: pointer;
}

.foldersList{
  ul {
    padding: 0;
    margin: 0;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      &:not(:last-child){
        margin-bottom: $margin-xs;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        background-color: $white;
        padding: $margin-xs 0 $margin-xs $margin-xs;
        border-radius: 8px;
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
        >div{
          flex: 1;
        }

        +.checkbox {
          margin-left: $margin-sm;
        }
  
        >div:first-child{
          display: flex;
          align-items: center;
          p{
            margin: 0;
            font-family: 'Manrope-Bold';
          }
          img{
            margin-right: $margin-xs;
          }
        }
  
        .date{
          flex: 0.1;
          margin-left: auto;
          padding: 0 $margin-xs;
        }
  
        .toggle {
          flex: 0.05;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          span {
            padding: 0 $margin-xs;
            opacity: 0.5;
            transition: opacity 0.4s ease-in-out;
            &:hover {
              opacity: 1;
            }
          }
        }

      }


    }
  }
}