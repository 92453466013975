@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.containerEvent {
  position: relative;
  flex: 1;

  &.done {
    opacity: 0.5;
  }

  &.notAssign {
    .event {
      position: relative;
    }
  }

  &:not(.notAssign) {
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    .event {
      position: absolute;
    }
  }

  .event {
    position: relative;
    border-radius: 4px;
    background-color: $white;
    padding: 5px;
    min-height: 27px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    &:after {
      content : '';
      display: block;
      position: absolute;
      height: 100%;
      width: 3px;
      top: 0; left: 0;
    }

    .background {
      position: absolute;
      z-index: 0;
      top: 0; left: 0;
      bottom: 0; right: 0;
      opacity: 0.35;
    }

    .border {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
    }

    .content {
      position: relative;
      display: flex;
      z-index: 2;
      padding-left: 4px;
      padding-right: 6px;

      >div:first-child {
        min-width: 26%;
        .client {
          font-family: 'Manrope-SemiBold';
          line-height: 18px;
  
          svg {
            position: relative;
            top: 1px;
            margin-right: 2px;
          }
  
          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
  
          .linkedTaskNumber {
            font-family: 'Manrope-SemiBold';
            color: $text-light;
            margin-left: 7px;
            img {
              position: relative;
              top: -2px;
              vertical-align: bottom;
              height: 15px;
              margin-right: 2px;
            }
          }
  
          span {
            font-family: 'Manrope-Regular';
          }
        }
      }

      .transport {
        width: 26%;
        margin-left: $margin-xs;
        padding: 0 $margin-xs;
        border-left: solid 1px rgba($primary-color, 0.2);

        p {
          font-family: "Manrope-ExtraBold";
          span {
            font-family: "Manrope-Regular";
          }
        }

        +.comments {
          max-width: 44%;
          width: 100%;
        }
      }

      .comments {
        margin-left: $margin-xs;
        padding: 0 $margin-xs;
        border-left: solid 1px rgba($primary-color, 0.2);
        h5 {
          font-family: "Manrope-ExtraBold";
          font-size: $small;
          margin: 0;
        }
        p {
          font-size: $small;
        }
      }

    }

    .steps {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      margin: 0;
      li {
        list-style: none;
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $secondary-color;
        &:not(.done) {
          opacity: 0.5;
        }
      }
    }

    .icons {
      position: absolute;
      right: 3px;
      bottom: 2px;
      .comment {
        color: $secondary-color;
        font-size: 10px;
        height: 13px;
        +.warning {
          margin-top: -2px;
        }
      }
      .warning {
        color: $warn;
        font-size: 12px;
        height: 13px;
      }
    }



    p {
      margin: 0;
    }

  }

}

.modal {
  background-color: $white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  border: 1px solid rgba(113, 122, 151, 0.21);
  border-radius: 8px;
  transform: translate(-10px, -10px);
  min-width: 240px;
  max-width: 320px;
  

  .container {
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 18px;
      margin-top: 5px;
      > div {
        cursor: pointer;
        color: $text-light;
      }

      .edit, .duplicate {
        margin-left: 7px;
      }

      .close {
        svg {
          transform: rotate(45deg);
        }
      }
    }

    .detail {
      display: flex;
      flex-wrap: wrap;
      margin-top: -5px;
      padding: 0 $margin-sm 0 $margin-sm;

      .type {
        position: relative;
        top: -3px;
        width: 22px;
        height: 22px;
        border-radius: 3px;
        margin-right: $margin-xs;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }

      .date {
        text-transform: capitalize;
      }

      h5, p {
        margin: 0;
      }

      .containerField{
        margin-bottom: $margin-xs;
      }

      .clients{
        .client {
          padding: $margin-xs 0;

          h5 {
            font-size: $medium;
            margin-bottom: 3px;
            span {
              font-family: 'Manrope-Regular';
            }
          }

          p{
            margin: 0;
          }

          &:not(:last-child) {
            border-bottom: 1px solid rgba(113, 122, 151, 0.11);
          }

        }
      }

      .carrier {
        padding: $margin-xs 0;
        border-top: 1px solid rgba(113, 122, 151, 0.11);
      }

      .clients,
      .carrier {
        ul {
          margin: 3px 0;
          padding-left: $margin-md;
        }
      }

      .transport {
        width: 100%;
        padding-top: $margin-xs;
        border-top: solid 1px rgba($primary-color, 0.2);
        margin: 0 0 $margin-xs 0;
        p {
          font-size: $small;
          margin-bottom: 3px;
          font-family: "Manrope-ExtraBold";
          span {
            font-family: "Manrope-Regular";
          }
        }
      }

      .comments {
        width: 100%;
        padding-top: $margin-xs;
        border-top: solid 1px rgba($primary-color, 0.2);
        h5 {
          font-family: "Manrope-ExtraBold";
          font-size: $small;
          margin: 0 0 5px 0;
        }
        p {
          font-size: $small;
          margin: 0 0 $margin-xs 0;
        }
      }

    }

    .steps {
      border-top: 1px solid rgba(113, 122, 151, 0.11);
      padding: $margin-xs $margin-sm $margin-sm $margin-sm;
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        padding: 0;
        margin: 0;
        li {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          list-style: none;

          >span {
            display: flex;
            align-items: center;
          }

          >span:not(.done) {
            opacity: .35;
            filter: grayscale(100%);
          }

          button {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            font-size: 30px;
            padding: 0;
            background-color: transparent;
            border: none;
            color: $primary-color;
          }

          img {
            margin-right: $margin-xs;
          }

          &:nth-child(odd):after{
            content : '';
            position: absolute;
            display: block;
            width: 1px;
            height: 100%;
            background-color: rgba(113, 122, 151, 0.11);
            right: -8px;
          }
        }
      }
    }

    button {
      width: 100%;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }



  }
}

.modalTask {
  background-color: $white;
  border-radius: $radius;
  padding-top: $margin-md;

  .containerField {
    padding: 0 $margin-md;
  }

  >p {
    padding: 0 $margin-md;
    margin: 0 0 $margin-sm 0;
    &.errorMessage {
      font-family: 'Manrope-Bold';
      color: $warn;
    }
  }

  button {
    width: 100%;
    height: 40px;
    border-radius: 0 0 $radius $radius;
  }
  
}
