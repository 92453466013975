@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.navbar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 176px;
  overflow: hidden;
  background: $white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  padding: 0 $margin-xs 0 $margin-sm;

  @media screen and (max-width: $sm-screen) {
    z-index: 2000;
  }

  // ON TABLET
  @media screen and (max-width: $md-screen) {
    height: 80px;
    width: 100%;
    transition: all 0.4s ease-in-out;
    &.opened {
      color: $white;
      background-color: $text-color;
      height: 100%;
      border-radius: 0;
      h1,
      li,
      a,
      p {
        color: $white;
      }
    }
  }

  @media screen and (min-width: $md-screen) {
    border-radius: 0 22px 22px 0;
    padding: 0;
  }

  .logo {
    display: none;
    margin: $margin-sm 0;
    img {
      width: 100%;
    }
    @media screen and (min-width: $md-screen) {
      display: block;
    }
  }

  .header-tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    min-height: 80px;
    width: 100%;
    @media screen and (min-width: $md-screen) {
      display: none;
    }
    h1 {
      font-size: $medium;
      margin: 0;
    }
  }

  .select-agency,
  .containerButton {
    width: 100%;
    padding: 0 $margin-sm;
    margin-bottom: $margin-sm;
  }

  //* Change min-width for the select container
  //* StyledSelect
  .select-agency>div>div{
    min-width: inherit;
  }

  .containerButton button {
    width: 100%;
    @media screen and (max-width: $md-screen) {
      background: $text-light;
      border-color: $text-light;
      color: $white;
      svg {
        color: $white;
      }
    }
    svg {
      top: -1px;
    }
  }

  ul {
    flex: 1;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "Manrope-SemiBold";
        font-size: $small;
        color: $text-light;
        text-decoration: none;
        padding: $margin-xs $margin-sm;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 30px;
          height: 2px;
          display: block;
          background-color: $primary-color;
          transition: all 0.2s ease-in-out;
          transform: translate(-100%, -50%) scale(0, 1);
          transform-origin: left;
        }

        &.active {
          color: $primary-color;
          &:before {
            transform: translate(-100%, -50%) scale(1, 1);
          }
        }

        @media screen and (min-width: $md-screen) {
          &:before {
            height: 100%;
            width: 10px;
            border-radius: 5px;
            transform: translate(-100%, -50%);
          }

          &.active:before {
            transform: translate(-50%, -50%);
          }
        }

        .icons {
          display: inline-block;
          width: 18px;
          margin-right: $margin-xs;
        }
      }
    }
  }

  .profile {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $margin-xs $margin-sm $margin-sm $margin-sm;
    width: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      height: 1px;
      width: 90%;
      background-color: $text-light;
      opacity: 0.3;
      transform: translateX(-50%);
    }
    @media screen and (min-width: $md-screen) {
      width: auto;
      justify-content: center;
    }
    p {
      display: inline-flex;
      font-family: "Manrope-Medium";
      white-space: nowrap;
      margin-right: $margin-xs;
    }
    .initials {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: $margin-xs;
      border-radius: 50%;
      background-color: $primary-color;
      > span {
        position: relative;
        top: -1px;
        font-family: "Manrope-Bold";
        font-size: $small;
        color: $white;
      }
    }

    .logout {
      position: relative;
      top: 2px;
      font-size: 28px;
      cursor: pointer;
      @media screen and (min-width: $md-screen) {
        font-size: 18px;
      }
      svg {
        color: $primary-color;
      }
    }
  }
}
