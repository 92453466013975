@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.home {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-width: 100%;
  @media screen and (min-width: $md-screen) {
    min-width: inherit;
  }

  .search {
    @include container();
    // padding-top: 0;
    padding-bottom: 0;
  }

  .container {
    position: relative;
    @include container();
    flex: 1;
    overflow: auto;
    padding-top: 0;

    .loader{
      width: 40px;
    }

    > div:not(.loader) {
      left: $margin-md;
      right: $margin-md;
      min-height: 100%;
      height: 100%;
    }

    .noCase {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      p {
        text-align: center;
      }
      img {
        display: block;
      }
    }

    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: $margin-xs;
      h3 {
        font-family: "Manrope-Regular";
        font-weight: normal;
        font-size: $small;
        letter-spacing: 1px;
        color: $text-light;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .lastCases {
      @media screen and (min-width: $sm-screen) {
        margin-bottom: $margin-md;
      }
      .list {
        display: flex;
        padding: 0;
        margin: 0;
        @media screen and (max-width: $sm-screen) {
          overflow-x: auto;
          width: calc(100% + $margin-md);
          padding-right: $margin-md;
          padding-bottom: $margin-sm;
        }
        li {
          list-style: none;
        }
        > li:not(:last-child) {
          margin-right: $margin-sm;
        }
      }
    }

    .containerCases,
    .containerFolders {
      > div:last-child {
        margin-bottom: $margin-md;
      }
    }
  }
}
