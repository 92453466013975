.overlay-modal{
  position: fixed;
  z-index: 1002;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  z-index: 1003;
  width: calc(100%);
  max-width: 400px;
  bottom:20px;
  left:50%;
  transform: translate(-50%, 0);
  padding-left: 55px;
  .close{
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(50%, 0%);
    margin: 0;
    font-size: 28px;
    cursor: pointer;
    svg {
      position: relative;
      top: -6px;
      left: -10px;
      transform: rotate(45deg);
    }
  }
}
