@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.header {
  display: flex;
  padding-bottom: $margin-sm;
  border-bottom: solid 1px $medium-grey;

  img {
    margin-right: $margin-sm;
    width: 30px;
  }

  p {
    margin: 0;
    &.client span{
      font-family: 'Manrope-SemiBold';
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px $medium-grey;
  font-size: 45px;
  &:not(.disabled) {
    cursor: pointer;
  }
}

.baskets {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: $margin-sm 0;
  margin-bottom: $margin-sm;
  border-bottom: solid 1px $medium-grey;
  .basket {
    label {
      font-family: "Manrope-Bold";
      color: $text-color;
    }
    p {
      margin: 0;
    }
  }
}

.fieldNumber {
  display: flex;
  >div:first-child {
    margin-right: $margin-sm;
  }
  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

}

.articles {
  padding: 0;
  margin: $margin-xs 0 0 0;
  overflow: auto;
  position: relative;

  &.loading {
    pointer-events: none;
  }

  li {
    display: flex;
    align-items: center;
    list-style: none;
    margin-top: $margin-xs;
    .content {
      flex: 1;
      padding: $margin-xs;
      margin-right: $margin-xs;
      border: solid 1px $medium-grey;
      border-radius: 4px;
      box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);

      +div {
        position: relative;
        top: -2px;
      }

    }

    h3 {
      font-size: $regular;
      color: $text-light;
      text-decoration: underline;
      margin: 5px 0 3px 0;
    }

    .article {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .ref {
        width: 100%;
        margin: 0;
      }

      > div:nth-child(2) {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        p {
          font-family: "Manrope-Bold";
          color: $text-light;
          margin: 0;
          &.articleName {
            flex: 1;
            color: $text-color;
            padding-right: 5px;
            span:first-child {
              text-transform: uppercase;
            }
            span:nth-child(2) {
              font-family: "Manrope-SemiBold";
              color: $text-light;
            }
          }
        }
      }

      &.withSubtitutes .quantity p span:first-child {
        color: $warn;
      }

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        // >div {
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        // }
        
        .contentQuantity {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          p {
            margin-right: $margin-xs;
          }
          .comment {
            text-align: center;
            width: 100%;
          }
        }

        .plannedQuantity {
          border-left: solid 1px $text-light;
          padding-left: $margin-xs;
          margin-left: $margin-xs;
        }

        p {
          font-family: "Manrope-SemiBold";
          font-size: $medium;
          margin: 0;
          text-align: right;
          min-width: 22px;
        }

        .icon {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          color: white;
          margin-right: 10px;

          span, svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          
          &.ok,
          &.damaged,
          &.hs {
            font-family: "Manrope-Bold";
            font-size: 16px;
          }

          &.hs{
            background-color: #D95E60;
          }
          &.damaged {
            background-color: #FFAC32;
          }
          &.ok {
            background-color: $primary-color;
          }
        }

      }

      .comments {
        .comment {
          font-family: "Manrope-Bold";
          margin: 0;
          &.hs {
            color: #D95E60;
          }
          &.damaged {
            color: #FFAC32;
          }
        }
      }

    }
  }
}

.substitutes {
  .substitute {
    display: flex;
    align-items: center;

    p {
      font-family: "Manrope-Bold";
      margin: 0;
    }

    .substituteName {
      color: $text-color;
      text-transform: uppercase;
    }

    .comment {
      font-family: "Manrope-Regular";
      color: $text-light;
      margin-bottom: $margin-xs;
    }

    .quantity p {
      font-family: "Manrope-SemiBold";
    }

  }
}

.containerButton {
  display: flex;
  justify-content: center;
  margin-top: $margin-sm;
}