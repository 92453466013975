@import '../../../styles/_variables.scss';

.form{
  background-color: $white;
  padding: $margin-md;
  border-radius: $radius;

  h1{
    text-align: center;
    margin: 0 0 $margin-sm 0;
  }

  .containerField{
    margin-bottom: $margin-sm;
  }
  .errorMessage{
    margin-bottom: $margin-sm;
    background-color: rgba(245, 91, 93, .1);
    padding: 5px $margin-xs;
    border-radius: $radius;
    p{
      font-family: 'Manrope-Bold';
    }
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}