@import '../../../styles/_variables.scss';

.burger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: 50px;
  .line1, .line2, .line3 {
    fill: none;
    stroke: $primary-color;
    stroke-width: 4;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
  }
  .line2 {
    stroke-dasharray: 60 60;
  }
  .line3 {
    stroke-dasharray: 60 207;
  }
  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }
    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }
    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }
  }
}

