@import '../../styles/_variables.scss';

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $margin-xs;
  h3{
    font-family: 'Manrope-Regular';
    font-weight: normal;
    font-size: $small;
    letter-spacing: 1px;
    color: $text-light;
    text-transform: uppercase;
    margin: 0;
  }
}

.containerCases,
.containerFolders{
  >div:last-child {
    padding-bottom: $margin-md;
  }
}