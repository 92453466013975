@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.ged {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-width: 100%;
  width: 100%;
  @media screen and (min-width: $md-screen) {
    min-width: inherit;
  }
  .container {
    position: relative;
    @include container();
    flex: 1;
    overflow: auto;
    .search {
      padding: 0 0 $margin-md 0;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $margin-xs;

      button.export{
        height: 31px;
        font-size: 18px;
        svg{
          position: relative;
          left: 3px;
        }
      }

      h1 {
        margin: 0;
      }

      h3 {
        font-family: "Manrope-Regular";
        font-weight: normal;
        font-size: $small;
        letter-spacing: 1px;
        color: $text-light;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .breadcrumbTrail {
      @include breadcrumb();
    }

    .lastFolders {
      border-bottom: solid 1px rgba(113, 122, 151, 0.2);
      margin-bottom: $margin-md;
      @media screen and (min-width: $sm-screen) {
        padding-bottom: $margin-sm;
      }

      .noResult {
        color: $text-light;
      }

      .list {
        display: flex;
        padding: 0;
        margin: 0;
        width: 100%;
        @media screen and (max-width: $sm-screen) {
          overflow-x: auto;
          width: calc(100% + $margin-md);
          padding-right: $margin-md;
          padding-bottom: $margin-sm;
        }
        li {
          list-style: none;
        }
        > li:not(:last-child) {
          margin-right: $margin-sm;
        }
      }
    }
    

    .files {
      .header .containerButtons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        .btnSend {
          margin-right: $margin-xs;
        }
      }

      
      .listFiles {
        margin-bottom: $margin-sm;
      }

      .containerInputField {
        margin-bottom: $margin-sm;

        &.disabled {
          pointer-events: none;
        }
      }

      .errorMessage {
        flex: 1;
        min-width: 100%;
        background-color: rgba(245, 91, 93, 0.1);
        border-radius: $radius;
        margin-bottom: $margin-sm;
        padding: 3px $margin-sm;
        p {
          text-align: left;
        }
      }
    }
  }
}
